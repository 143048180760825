<template>
  <popup-modal ref="popup">
    <div class="modal-dialog">
      <div class="modal-content" style="max-height: 90vh">
        <div class="modal-body scrollable">
          <div>
            <div class="col d-flex align-items-center justify-content-center">
              <!--                Title-->
              <span class="h5 text-primary m-0"> Reject Leave Request </span>
            </div>
          </div>

          <div class="col-12 align-items-center my-3">
            <!--                    Description-->
            <div class="form-row">
              <label class="">Reason: </label>
              <textarea
                name="cancelReason"
                class="form-control"
                v-model="cancelReason"
                cols="30"
                rows="3"
                placeholder="Please enter a reason (optional)"
              ></textarea>
            </div>
          </div>

          <!--              Buttons-->
          <div class="row justify-content-center">
            <div class="col text-right">
              <button
                @click="_cancel"
                :class="`btn btn-secondary w-50`"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
            <div class="col text-left">
              <button
                :disabled="btnLoading"
                @click.prevent="save"
                :class="`btn btn-primary w-50`"
              >
                <template v-if="btnLoading">
                  <span class="mr-2 spinner-border spinner-border-sm"></span>
                </template>
                Reject
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </popup-modal>
</template>

<script>
import { defineComponent } from "vue";
import PopupModal from "./modals/PopupModal.vue";
import WorkForceService from "@/shared/application/work-force-service-proxy";
import { notify } from "@/services/helpers";

export default defineComponent({
  name: "DropDownModal",

  components: {
    PopupModal,
  },

  data: () => ({
    // Parameters that change depending on the type of dialogue

    // Private variables
    resolvePromise: undefined,
    rejectPromise: undefined,

    workForceService: new WorkForceService(),
    leaveId: -1,
    btnLoading: false,
    cancelReason: "",
  }),

  methods: {
    resetData() {
      this.leaveId = -1;
    },

    async save() {
      this.btnLoading = true;
      await this.workForceService
        .put(
          `/leave/reject/${this.leaveId}?rejectReason=${this.cancelReason}`,
          Number(this.leaveId)
        )
        .then((response) => {
          if (!response.isError) {
            this.btnLoading = false;
            this._confirm();
          } else {
            notify(response.errors, "Error", "danger");
            this.btnLoading = false;
            this._reject("Oops! Something went wrong.");
          }
        });

      this.btnLoading = false;
    },

    async show(opts = {}) {
      this.leaveId = await opts.leaveId;

      // Once we set our config, we tell the popup modal to open
      this.$refs.popup.open();

      // Return promise so the caller can get results
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },
    _confirm() {
      this.$refs.popup.close();
      this.leaveId = -1;
      this.resolvePromise(true);
    },

    _cancel() {
      this.$refs.popup.close();
      this.resolvePromise(false);
      this.leaveId = -1;
      // Or you can throw an error
      // this.rejectPromise(new Error('User cancelled the dialogue'))
    },

    _reject() {
      this.$refs.popup.close();
      this.rejectPromise();
      this.leaveId = -1;
      // Or you can throw an error
      // this.rejectPromise(new Error('User cancelled the dialogue'))
    },
  },
});
</script>

<style scoped>
.icon-mod {
  border-radius: 5px;
  font-size: 100%;
}
.scrollable {
  max-height: 90vh;
  overflow-y: auto;
}
</style>
